<template>
  <div class="customize-warp">
    <div class="name">
      <div class="name-box">
        <img src="../assets/ic_customer_102.svg" alt="" />
        {{ $t("index.title") }}
      </div>
    </div>
    <div class="customize-box">
      <div class="customize-title">{{ $t("index.help.title") }}</div>
      <div class="content">
        <div class="content-title">{{ $t("index.help.desc") }}</div>
        <div class="content-list">
          <div
            v-for="(item, index) in $t('index.help.list')"
            :key="index"
            class="content-item"
          >
            <img src="../assets/item-1.svg" v-if="index === 0" alt="" />
            <img src="../assets/item-2.svg" v-if="index === 1" alt="" />
            <img src="../assets/item-3.svg" v-if="index === 2" alt="" />
            <img src="../assets/item-4.svg" v-if="index === 3" alt="" />
            <div class="content-item-title">{{ item }}</div>
          </div>
        </div>
      </div>
      <div class="btn" @click="getChat">{{ $t("index.help.btn") }}</div>
      <div class="tips">
        <div class="tips-title" :data-content="$t('index.desc.title')"></div>
        <div class="tips-desc-box">
          <div
            v-for="(item, index) in descList"
            :class="`tips-${index + 1}`"
            :key="index"
          >
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <Chat ref="chat" :title="$t('index.title')" />
  </div>
</template>

<script>
import Chat from "./chat.vue";
export default {
  name: "index-warp",
  components: { Chat },
  computed: {
    descList() {
      return this.$t("index.desc.list");
    },
  },
  methods: {
    getChat() {
      this.$refs.chat.openChat();
    },
  },
  mounted() {
    this.$amplitude.getInstance().logEvent("openview");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@media only screen and (min-width: 751px) {
  .customize-warp {
    min-width: 340px;
    margin: auto;
  }
  .name {
    padding: 20px 0;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #090b29;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 80px;
    .name-box {
      width: 100%;
      padding: 0 40px;
      max-width: 1200px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      font-weight: bold;
    }
    img {
      margin-right: 10px;
      width: 40px;
    }
  }

  .customize-box {
    width: 100%;
    max-width: 980px;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    .customize-title {
      color: #000;
      text-align: center;
      font-size: 32px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 40px;
    }
    .content {
      border-radius: 8px;
      background: #f2f5fa;
      height: 280px;
      text-align: center;
      margin-bottom: 40px;
      .content-title {
        color: #000;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 40px 0;
      }
      .content-list {
        display: flex;
        justify-content: center;
        gap: 56px;
        .content-item {
          flex: 0 0 160px;
          img {
            width: 60px;
          }
          .content-item-title {
            margin-top: 16px;
            color: #000;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    .btn {
      cursor: pointer;
      color: #fff;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
      display: inline-flex;
      padding: 16px 120px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #5c69f7;
      box-shadow: 0px 7px 20px 0px #6a74df;
      margin: 0 auto;
      margin-bottom: 40px;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    .tips-desc-box {
      width: 100%;
      max-width: 800px;
      margin: auto;
      color: #6e6e6e;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 24px;
    }
    .tips-title {
      position: relative;
      margin: 20px 0;
      border-top: 1px solid #c5d5e4;
      &::after {
        content: attr(data-content);
        position: absolute;
        top: -18px;
        left: 50%; /* Position at the center */
        transform: translateX(-50%); /* Center horizontally */
        background-color: #fff; /* Set background color to match the background */
        padding: 0 30px; /* Adjust padding as needed */
        color: #000;
        font-size: 24px;
        font-weight: 600;
        line-height: normal;
        text-align: center;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
  .customize-warp {
    min-width: 100%;
    margin: auto;
    padding-bottom: 200px;
  }
  .name {
    padding: 10px;
    font-size: 20px;
    color: #090b29;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    border-bottom: 1px solid #e9e9e9;
    margin-bottom: 35px;
    .name-box {
      width: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
    }
    img {
      width: 40px;
      margin-right: 6px;
    }
  }
  .customize-box {
    width: calc(100% - 32px);
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 0 16px;
    .customize-title {
      color: #000;
      text-align: center;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      text-align: center;
      margin-bottom: 20px;
    }
    .content {
      width: 100%;
      border-radius: 8px;
      background: #f2f5fa;
      padding-bottom: 30px;
      text-align: center;
      margin-bottom: 40px;
      .content-title {
        color: #000;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        padding: 20px 0;
        padding-bottom: 25px;
        max-width: 300px;
        margin: 0 auto;
      }
      .content-list {
        display: flex;
        justify-content: center;
        gap: 14px;
        padding: 0 10px;
        .content-item {
          flex: 1;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          img {
            width: 40px;
          }
          .content-item-title {
            margin-top: 16px;
            color: #000;
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
    .btn {
      cursor: pointer;
      color: #fff;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      text-transform: capitalize;
      display: inline-flex;
      width: calc(100% - 36px);
      padding: 16px 0;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 4px;
      background: #5c69f7;
      box-shadow: 0px 7px 20px 0px #6a74df;
      margin: 0 auto;
      margin-bottom: 40px;
      transition: transform 0.3s ease;
      &:hover {
        transform: scale(1.1);
      }
    }
    .tips-desc-box {
      width: 100%;
      max-width: 800px;
      margin: auto;
      color: #6e6e6e;
      text-align: center;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 30px;
    }
    .tips-title {
      position: relative;
      margin: 20px 0;
      border-top: 1px solid #c5d5e4;
      &::after {
        content: attr(data-content);
        position: absolute;
        top: -15px;
        left: 50%; /* Position at the center */
        transform: translateX(-50%); /* Center horizontally */
        background-color: #fff; /* Set background color to match the background */
        padding: 0 0.2rem; /* Adjust padding as needed */
        color: #000;
        font-size: 20px;
        font-weight: 600;
        line-height: normal;
        text-align: center;
        width: 60%;
      }
    }
  }
}
</style>
