<template>
  <div id="app">
    <Index v-if="!loading"/>
  </div>
</template>

<script>
import Index from "../components/index.vue";

export default {
  name: "Home",
  data(){
    return {
      loading:true
    }
  },
  components: {
    Index,
  },
  mounted() {
    this.setLocales()
  },
  methods: {
    setLocales() {
      const localesJson = require.context(
        "../locales",
        true,
        /[A-Za-z0-9-_,\s]+\.json$/i
      );
      const locale =  localesJson.keys().map((key) => {
        const matched = key.match(/([A-Za-z0-9-_]+)\./i);
        if (matched && matched.length > 1) {
          const locale = matched[1];
          return locale;
        }
      });
      const language = navigator.language || navigator.userLanguage ||'en';
      if(locale.includes(language)){
        console.log(language,"language>>>")
        this.$i18n.locale = language;
      }
      this.loading = false;
    },
  },
};
</script>

<style>
</style>
